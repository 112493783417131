
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
			<form @submit.prevent="updateCommissions" autocomplete="off">
				<v-layout row wrap>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-select class="mx-1" clearable :items="employees" v-model="commissions.employee_id" dense filled outlined item-text="employee_name" item-value="employee_id" :return-object="false" :label="$store.getters.language.data.employees.employee_name">
						</v-select>
					</v-flex>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="commissions.commission_amount" type="text" :label="$store.getters.language.data.commissions.commission_amount" class="mx-1" filled outlined dense required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="commissions.commission_date" type="date" :label="$store.getters.language.data.commissions.commission_date" class="mx-1" filled outlined dense>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg6 xl6 md6 sm6>

						<v-textarea v-model="commissions.commission_note" type="textarea" :label="$store.getters.language.data.commissions.commission_note" dense class="mx-1" filled outlined required>
						</v-textarea>
					</v-flex>

					<!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="commissions.commission_insert_date" type="datetime-local" :label="$store.getters.language.data.commissions.commission_insert_date" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex> -->

					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-select class="mx-1" clearable :items="users" v-model="commissions.user_id" dense filled outlined item-text="user_name" item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_name" disabled>
						</v-select>
					</v-flex>
					<v-flex xs12 lg2 xl2 md2 sm4>
						<v-btn color="success" type="submit">{{$store.getters.language.data.commissions.update_btn}}</v-btn>
					</v-flex>
				</v-layout>
			</form>
		</v-container>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from './../../requests/commissions.request.js'
	import employeesRequest from '../../requests/employees.request.js';
	export default {
		data() {
			return {
				commissions: {},
				employees: [],
				id: '',
				loading: true,
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
			}
		},
		computed: {

			// employees(){
			//     return this.$store.getters.employees_list
			// },

			users() {
				return this.$store.getters.users_list
			},

		},
		mounted() {
			this.id = this.$route.params.id
			this.getEmployees()
			this.getOneCommissions()
		},
		methods: {
			getEmployees() {
				this.loading = true
				employeesRequest.getAllEmployees().then(r => {
					if (r.status == 200) {
						this.employees = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Employees',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Employees',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			getOneCommissions() {
				this.loading = true
				requests.getOneCommissions(this.id).then(r => {
					if (r.status == 200) {
						this.commissions = r.data.row
						this.commissions.commission_date = this.commissions.commission_date.split('T')[0]
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Commissions',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Commissions',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			updateCommissions() {
				this.loading = true
				delete this.commissions.commission_insert_date
                this.commissions.user_id = this.$store.getters.user.user_id
				requests.updateCommissions(this.id, this.commissions).then(r => {
					if (r.status == 200) {
						this.snackbar = {
							value: true,
							text: 'Commissions Updated',
							color: 'success'
						}
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Update Faild',
							color: 'error'
						}
						this.loading = false
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Update Faild',
							color: 'error'
						}
						this.loading = false
					})
					.finally(() => {
						this.loading = false
					})
			},
		},
	}
</script>
                    