
import axios from 'axios';
export default {
    
    async createCommissions(params)  {
        return await axios.post(`commissions/create` , params)
    },
    async createCommissionsList(params)  {
        return await axios.post(`commissions/create/list` , params)
    },
    async updateCommissionsColumn(column , value , data)  {
        return await axios.put(`commissions/update_list?${column}=${value}` , data)
    },
    async deleteCommissionsList(list)  {
        return await axios.delete(`commissions/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportCommissions(column , value)  {
        return await axios.get(`commissions/report?${column}=${value}`)
    },
    async getAllCommissions()  {
        return await axios.get(`commissions/all`)
    },
    async getOneCommissions(commission_id)  {
        return await axios.get(`commissions/all/${commission_id}`)
    },
    async getCommissionsByColumn(column , value)  {
        return await axios.get(`commissions/filter?column=${column}&value=${value}`)
    },
    async deleteCommissions(commission_id)  {
        return await axios.delete(`commissions/delete/${commission_id}`)
    },
    async updateCommissions(commission_id , params)  {
        return await axios.put(`commissions/update/${commission_id}` , params)
    }
}